import geolocations from "assets/bosta_geolocations.json";

export const formatCompany = (company, user) => {
  const email = company.contactEmail || user.email || "";

  return {
    ...company,
    email,
    contactEmail: email,
    contactPhone: user.loginPhoneNumber || "",
    address: formatAddressToAPI(company.address),
  };
};

export const formatFields = (company) => {
  return {
    ...company,
    ...(company?.seoKeywords && { seoKeywords: company.seoKeywords.join(",") }),
    ...(company?.defaultStoreLanguage && {
      defaultStoreLanguage: company.defaultStoreLanguage,
    }),
    ...(company?.communicationLanguage && {
      communicationLanguage:
        company.communicationLanguage === "English" ? "communication_english" : "communication_arabic",
    }),
  };
};

export const formatAddressToAPI = (address) => {
  return {
    address_type: address.address_type,
    apartment: address.apartment,
    floor: address.floor,
    building: address.building,
    addressLine1: address.line,
    city: address.city?.name_en,
    region: address.zone?.name_en,
    district: address.district?.name_en,
    country: address.country.name || "Egypt",
  };
};

export const formatAddressFromAPI = (address) => {
  if (!address) {
    return {
      line: "",
      apartment: "",
      floor: "",
      building: "",
      city: null,
      zone: null,
      district: null,
    };
  }
  const city = geolocations[0].children.find((c) => c.name_en === address.city) || "";
  let zone = null;
  if (city) zone = city.children.find((z) => z.name_en === address.region) || null;

  let district = null;
  if (zone) district = zone.children.find((d) => d.name_en === address.district) || null;

  return {
    line: address?.addressLine1,
    apartment: address?.apartment,
    floor: address?.floor,
    building: address?.building,
    city,
    zone,
    district,
    country: address?.country || "Egypt",
  };
};

export const getCity = (locations, locationId) => {
  locationId = locationId.split("/")[1]; // to get city id from ancestry
  return locations?.find((o) => o.id === parseInt(locationId)).name;
};

export const formatExceptions = (addresses) => {
  let regions = [];
  let country = "Egypt";
  addresses?.forEach(({ locationType, name, ancestry, pricing }) => {
    if (locationType == "country") country = name;
    else if (locationType !== "country" && Object.keys(pricing).length)
      regions.push({
        country: country,
        city: locationType === "region" ? getCity(addresses, ancestry) : name,
        region: locationType === "region" ? name : null,
        shippingFees: pricing.first,
        additionalKilosFees: pricing.extra,
      });
  });

  return regions;
};

export const formatCities = (addresses) => {
  let cities = [];
  addresses?.forEach((address) => {
    address.locationType === "city" ? cities.push(address.name) : null;
  });
  return cities;
};

export const formatCompanyFields = (data) => {
  let payload = data.map((x) => x.fields);
  let result = Object.assign(...payload);
  return result;
};

export const formatStoreFeatures = (data) => {
  return data.filter((x) => x.isVisible);
};

export const formatCompanyDetails = (data) => {
  return {
    integrations: {
      gameball: {
        gameballApiKey: data.gameballApiKey,
        gameballTransactionKey: data.gameballTransactionKey,
      },
      googleSiteVerification: {
        googleAnalyticsKey: data.googleAnalyticsKey,
        googleTagManagerKey: data.googleTagManagerKey,
        googleSiteVerificationKey: data.googleSiteVerificationKey,
      },
      mailChimp: {
        mailchimpKey: data.mailchimpKey,
      },
      meta: data.metaKeys,
      tiktokPixel: {
        tiktokPixel: data.tiktokPixel,
        tiktokAccessToken: data.tiktokAccessToken,
      },
      facebookChat: {
        facebookChatKey: data.facebookChatKey,
      },
      paymentSettings: {
        paymentCurrency: data.paymentCurrency,
        paymobReceivingCurrency: data.paymobReceivingCurrency,
      },
      geidea: {
        geideaApiPassword: data.geideaApiPassword,
        geideaMerchantPublicKey: data.geideaMerchantPublicKey,
      },
      stripe: {
        stripeApiKey: data.stripeApiKey,
        stripePublicKey: data.stripePublicKey,
        stripeEndpointSecret: data.stripeEndpointSecret,
      },
      paytabs: {
        paytabsServerKey: data.paytabsServerKey,
        paytabsProfileId: data.paytabsProfileId,
      },
      gccpay: {
        gccpayTerminalId: data.gccpayTerminalId,
        gccpayPassword: data.gccpayPassword,
        gccpayMerchantKey: data.gccpayMerchantKey,
      },
      paymob: {
        paymobUsername: data.paymobUsername,
        paymobApiKey: data.paymobApiKey,
        paymobHmacSecret: data.paymobHmacSecret,
        paymobOnlineIntegrationId: data.paymobOnlineIntegrationId,
        paymobPaymentIframeId: data.paymobPaymentIframeId,
        paymobCashIntegrationId: data.paymobCashIntegrationId,
      },
      bank_installments: {
        useBankInstallmentsPayment: data.useBankInstallmentsPayment,
        paymobBankInstallmentsIframeId: data.paymobBankInstallmentsIframeId,
        paymobBankInstallmentsIntegrationId: data.paymobBankInstallmentsIntegrationId,
      },
      sympl: {
        useSymplPayment: data.useSymplPayment,
        paymobSymplIframeId: data.paymobSymplIframeId,
        paymobSymplIntegrationId: data.paymobSymplIntegrationId,
        symplStoreCode: data.symplStoreCode,
      },
      valu: {
        useValuPayment: data.useValuPayment,
        paymobValuIframeId: data.paymobValuIframeId,
        paymobValuIntegrationId: data.paymobValuIntegrationId,
      },
      mobileWallets: {
        useMobileWalletsPayment: data.useMobileWalletsPayment,
        paymobMobileWalletsIntegrationId: data.paymobMobileWalletsIntegrationId,
      },
      tabby: {
        useTabbyPayment: data.useTabbyPayment,
        paymobTabbyIntegrationId: data.paymobTabbyIntegrationId,
      },
      souhoola: {
        useSouhoolaPayment: data.useSouhoolaPayment,
        paymobSouhoolaIntegrationId: data.paymobSouhoolaIntegrationId,
        paymobSouhoolaIframeId: data.paymobSouhoolaIframeId,
      },
    },
    company: {
      basicInfo: {
        name: data.name,
        url: data.subdomain,
        contactPhone: data.contactPhone,
        priceDecimalPoints: data.priceDecimalPoints,
        defaultStoreLanguage: data.defaultStoreLanguage,
        communicationLanguage: data.communicationLanguage === "communication_english" ? "English" : "Arabic",
        referralCode: data.referralCode,
        contactEmails: data.contactEmails,
      },
      seo: {
        seoDescription: data.seoDescription,
        seoKeywords: data.seoKeywords ? data.seoKeywords.split(",") : [],
      },
      addressInfo: {
        address: formatAddressFromAPI(data.shippingAddress),
      },
      policies: {
        shippingPolicyEn: data.shippingPolicyEn,
        shippingPolicyAr: data.shippingPolicyAr,
        checkoutMessageEn: data.checkoutMessageEn,
        checkoutMessageAr: data.checkoutMessageAr,
        returnAndRefundPolicyAr: data.returnAndRefundPolicyAr,
        returnAndRefundPolicyEn: data.returnAndRefundPolicyEn,
        emailHeadline: data.emailHeadline,
      },
      customization: {
        priceTemplateAr: data.priceTemplateAr,
        priceTemplateEn: data.priceTemplateEn,
      },
      features: data.features,
      companyLinks: data.companyLinks,
      storeFeatures: data.storeFeatures,
      jumia: {
        jumiaAccessToken: data.jumiaAccessToken,
      },
    },
    shipping: {
      details: {
        addTaxes: data.addTaxes,
        courier: data.courier,
        country: data.country,
        shippingApiKey: data.shippingApiKey,
        khazenlyStoreName: data.khazenlyStoreName,
        khazenlyClientId: data.khazenlyClientId,
        khazenlyClientSecret: data.khazenlyClientSecret,
        khazenlyAccountName: data.khazenlyAccountName,
        mylerzBusinessCategory: data.mylerzBusinessCategory,
        mylerzWarehouseName: data.mylerzWarehouseName,
        useCardCollection: data.useCardCollection,
        useCashCollection: data.useCashCollection,
        useOnlinePayment: data.useOnlinePayment,
        useGeideaPayment: data.useGeideaPayment,
        useGccpayPayment: data.useGccpayPayment,
        useNewLocations: data.useNewLocations,
        useStripePayment: data.useStripePayment,
        usePaytabsPayment: data.usePaytabsPayment,
        useBankTransfer: data.useBankTransfer,
        shippingFeesCalculation: data.shippingFeesCalculation,
        shippingType: data.shippingType,
        shippingFees: data.shippingFees,
        extraKiloFees: data.extraKiloFees,
      },
      areas: {
        cities: formatCities(data.geolocations),
        disabledRegions: data.disabledRegions,
        geolocations: formatExceptions(data.geolocations),
      },
      cart: {
        minimumOrderTotal: data.minimumOrderTotal,
        minimumFreeShippingTotal: data.minimumFreeShippingTotal,
        codFee: data.codFee,
      },
    },
  };
};
