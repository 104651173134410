export default function () {
  return {
    loading: false,
    data: {
      integrations: {
        paymentSettings: {
          paymentCurrency: "",
          paymobReceivingCurrency: "",
        },
        geidea: {
          geideaApiPassword: "",
          geideaMerchantPublicKey: "",
        },
        gccpay: {
          gccpayTerminalId: "",
          gccpayPassword: "",
          gccpayMerchantKey: "",
        },
        paymob: {
          paymobUsername: "",
          paymobApiKey: "",
          paymobPaymentIframeId: 0,
          paymobOnlineIntegrationId: 0,
          paymobHmacSecret: "",
          paymobCashIntegrationId: 0,
          paymobPaymentIframeId: 0,
        },
        stripe: {
          stripeApiKey: "",
          stripePublicKey: "",
          stripeEndpointSecret: "",
        },
        paytabs: {
          paytabsServerKey: "",
          paytabsProfileId: "",
        },
        valu: {
          useValuPayment: false,
          paymobValuIntegrationId: 0,
          paymobValuIframeId: 0,
        },
        sympl: {
          useSymplPayment: false,
          paymobSymplIframeId: 0,
          paymobSymplIntegrationId: 0,
          symplStoreCode: "",
        },
        bank_installments: {
          useBankInstallmentsPayment: false,
          paymobBankInstallmentsIntegrationId: 0,
          paymobBankInstallmentsIframeId: 0,
        },
        mobileWallets: {
          useMobileWalletsPayment: false,
          paymobMobileWalletsIntegrationId: 0,
        },
        tabby: {
          useTabbyPayment: false,
          paymobTabbyIntegrationId: 0,
        },
        souhoola: {
          useSouhoolaPayment: false,
          paymobSouhoolaIntegrationId: 0,
          paymobSouhoolaIframeId: 0,
        },
        googleSiteVerification: {
          googleAnalyticsKey: "",
          googleTagManagerKey: "",
          googleSiteVerificationKey: "",
        },
        meta: [],
        tiktokPixel: {
          tiktokPixel: "",
          tiktokAccessToken: "",
        },
        facebookChat: {
          facebookChatKey: "",
        },
        mailChimp: {
          mailchimpKey: "",
        },
        gameball: {
          gameballApiKey: "",
          gameballTransactionKey: "",
        },
      },
      company: {
        basicInfo: {
          name: "",
          url: "",
          contactPhone: "",
          defaultStoreLanguage: "",
          communicationLanguage: "",
          referralCode: "",
          contactEmails: [],
        },
        addressInfo: {
          address: {
            line: "",
            secondLine: "",
            district: null,
            apartment: "",
            building: "",
            floor: "",
            city: null,
            zone: null,
            country: "",
            addressClarification: "",
            postalCode: "",
          },
        },
        seo: {
          seoDescription: "",
          seoKeywords: [],
        },
        policies: {
          shippingPolicyEn: "",
          shippingPolicyAr: "",
          checkoutMessageEn: "",
          checkoutMessageAr: "",
          returnAndRefundPolicyAr: "",
          returnAndRefundPolicyEn: "",
          emailHeadline: "",
        },
        customization: {
          priceTemplateAr: "",
          priceTemplateEn: "",
        },
        features: [],
        companyLinks: [],
        companyLocations: [],
        storeFeatures: [],
        jumia: {
          jumiaAccessToken: "",
        },
      },
      shipping: {
        details: {
          addTaxes: false,
          courier: "",
          country: "",
          shippingApiKey: "",
          mylerzBusinessCategory: "",
          mylerzWarehouseName: "",
          useCardCollection: false,
          useCashCollection: false,
          useOnlinePayment: false,
          useGeideaPayment: false,
          useGccpayPayment: false,
          useNewLocations: false,
          useStripePayment: false,
          usePaytabsPayment: false,
          useBankTransfer: false,
          shippingFeesCalculation: "at_cost",
          shippingType: "",
          shippingFees: 0.0,
          extraKiloFees: 0.0,
          khazenlyStoreName: "",
          khazenlyClientId: "",
          khazenlyClientSecret: "",
          khazenlyAccountName: "",
        },
        areas: {
          cities: [],
          disabledRegions: [],
          geolocations: [],
        },
        cart: {
          minimumOrderTotal: 0,
          minimumFreeShippingTotal: 0,
          codFee: 0,
        },
      },
    },
    platforms: {
      data: [],
      loading: false,
    },
    storeFeatures: {
      data: [],
      loading: false,
    },
  };
}
