import { convertToCentsByKeys, convertFromCentsByKeys, flattenObject, localizeObject } from "../../utils";

import { flattenExtra, flattenPricing, formatCollections, formatExtra } from "./utils";

const filterFees = (povs) => {
  return povs.filter((pov) => pov.fees > 0);
};

const flattenBasicInfo = (product) => {
  return {
    ...flattenObject(product, "name", ""),
    ...flattenObject(product, "description", ""),
    ...flattenObject(product, "ribbonLabel", ""),
    handle: product.handle,
  };
};

const formatBasicInfo = (product) => {
  return {
    ...localizeObject(product, "name", ""),
    ...localizeObject(product, "description", ""),
    ...localizeObject(product, "ribbonLabel", ""),
    handle: product.handle,
  };
};

// overrides falsy values (0/null/""/NaN) with a default value (min = 1, max = 1000)
const handleInventory = (inventory) => {
  let newInventory = inventory;

  newInventory.minQuantity = parseInt(inventory.minQuantity) || 1;
  newInventory.maxQuantity = parseInt(inventory.maxQuantity) || 1000;

  // temp fix until v1 shops respects is_limited flag
  if (!inventory.isLimited) {
    newInventory.minQuantity = 1;
    newInventory.maxQuantity = 1000;
  }

  return newInventory;
};

// override the product's status to sold_out if the user checked
// the isSoldOut flag in the inventory module
const handleStatus = (product) => {
  if (product.inventory.isSoldOut) return "sold_out";

  return product.status;
};

export const flattenProduct = (product) => {
  return {
    media: product.media?.map((image) => {
      return {
        id: image.id,
      };
    }),
    ...flattenBasicInfo(product.basicInfo),
    ...product.categorization,
    collection_ids: product.categorization.collections.map((collection) => collection.id),
    ...handleInventory(product.inventory),
    ...flattenPricing(product.pricing),
    ...product.shipping,
    status: handleStatus(product),
    hide_reviews: product.hideReviews,
    prodExtras: product.extras.map((extra) => flattenExtra(extra)),
    productOptions: product.variants.options,
    variants: product.variants.value.map((variant) => {
      return {
        id: variant.id,
        values: variant.values,
        ...handleInventory(variant.inventory),
        ...variant.pricing,
        ...convertToCentsByKeys(variant.pricing, ["price", "discountedPrice"]),
        ...variant.shipping,
        imageIds: variant.media,
      };
    }),
  };
};

export const formatProduct = (product) => {
  if (!product) return null;

  return {
    id: product.id,
    hasVariants: product.variants?.length > 0,
    additionalFees: filterFees(product.productOptionValues).map((pov) => pov.name),
    hasAdditionalFees: filterFees(product.productOptionValues).length > 0,
    media: product.imagesUrls.map((image, index) => {
      return {
        id: image.id,
        src: image.urls[0],
        thumb: index === 0 ? product.thumbUrl : null,
        progress: 1,
      };
    }),
    basicInfo: formatBasicInfo(product),
    categorization: {
      type: product.type,
      vendor: product.vendor,
      tags: product.tags || [],
      collections: formatCollections(product.collections),
      googleProductCategory: product.googleProductCategory,
    },
    inventory: {
      sku: product.sku,
      barCode: product.barCode,
      isTracked: product.isTracked,
      quantity: product.quantity,
      isLimited: product.isLimited,
      minQuantity: product.minQuantity,
      maxQuantity: product.maxQuantity,
      isSoldOut: product.status === "sold_out",
    },
    shipping: {
      isPhysical: product.isPhysical,
      addShippingFees: product.addShippingFees,
      weight: parseFloat(product.weight),
    },
    status: product.status,
    pricing: {
      pricedBy: product.pricedBy,
      pricingUnit: product.pricingUnit,
      isOnSale: product.isOnSale,
      isVat: product.isVat,
      ...convertFromCentsByKeys(product, ["price", "discountedPrice"]),
    },
    extras: product.prodExtras.map((extra) => formatExtra(extra)),
    variants: {
      options: product.productOptions,
      value: product.variants.map((variant) => {
        return {
          id: variant.id,
          values: variant.values,
          shipping: {
            isPhysical: variant.isPhysical,
            weight: variant.weight,
          },
          pricing: {
            isOnSale: variant.isOnSale,
            isVat: variant.isVat,
            ...convertFromCentsByKeys(variant, ["price", "discountedPrice"]),
          },
          inventory: {
            sku: variant.sku,
            barCode: variant.barCode,
            isTracked: variant.isTracked,
            quantity: variant.quantity,
            isLimited: variant.isLimited,
            minQuantity: variant.minQuantity,
            maxQuantity: variant.maxQuantity,
          },
          media: variant.imageIds,
        };
      }),
    },
    hideReviews: product.hideReviews,
    reviews: {
      averageRating: product.averageRating,
      totalReviews: product.totalReviews,
    },
  };
};
